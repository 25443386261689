<template>
    <nav class="mobile-nav" :class="{'mobile-nav--open': open}">
      <div class="container">
        <ul class="list-unstyled mt-5 px-4">
           <li class="">
             <a class="text-uppercase text-primary font-weight-bold mb-3 mobile-nav-heading" href="https://grameenfoundation.org/partners">Partners</a>
           </li>
         </ul>
        <ul class="list-unstyled p-4">
           <li class="mb-5">
              <div class="text-uppercase text-primary font-weight-bold mb-3 mobile-nav-heading">Solving Poverty</div>
              <ul class="list-unstyled">
                  <li><a class="mobile-nav-link" href="https://grameenfoundation.org/solving-poverty/our-impact">Our Impact</a></li>
                  <li><a class="mobile-nav-link" href="https://grameenfoundation.org/solving-poverty/community-agents">Community Agents</a></li>
                  <li><a class="mobile-nav-link" href="https://grameenfoundation.org/solving-poverty/digital-farming">Digital Farming</a></li>
                  <li><a class="mobile-nav-link" href="https://grameenfoundation.org/solving-poverty/mobile-money">Mobile Money</a></li>
              </ul>
           </li>
           <li class="mb-5">
             <div class="text-uppercase text-primary font-weight-bold mb-3 mobile-nav-heading">About Us</div>
             <ul class="list-unstyled">
                <li><a class="mobile-nav-link" href="https://grameenfoundation.org/about-us/why-grameen">Why Grameen</a></li>
                <li><a class="mobile-nav-link" href="https://grameenfoundation.org/about-us/the-grameen-family">The Grameen Family</a></li>
                <li><a class="mobile-nav-link" href="https://grameenfoundation.org/about-us/leadership">Leadership</a></li>
             </ul>
           </li>
           <li class="mb-5">
             <div class="text-uppercase text-primary font-weight-bold mb-3 mobile-nav-heading">Take Action</div>
             <ul class="list-unstyled">
                <li><a class="mobile-nav-link" href="https://grameenfoundation.org/take-action/donate">Donate</a></li>
                <li><a class="mobile-nav-link" href="https://grameenfoundation.org/take-action/ways-to-help">Ways to Help</a></li>
                <li><a class="mobile-nav-link" href="https://grameenfoundation.org/take-action/volunteer">Volunteer</a></li>
                <li><a class="mobile-nav-link" href="https://grameenfoundation.org/take-action/stay-up-to-date">Stay Up to Date</a></li>
             </ul>
           </li>
           <li class="mb-2">
             <div class="text-uppercase text-primary font-weight-bold mb-3 mobile-nav-heading">Stories</div>
              <ul class="list-unstyled">
                  <li><a class="mobile-nav-link" href="https://grameenfoundation.org/stories/stories-of-change">Stories of Change</a></li>
                  <li><a class="mobile-nav-link" href="https://grameenfoundation.org/stories/press-releases">Press Releases</a></li>
                  <li><a class="mobile-nav-link" href="https://grameenfoundation.org/stories/blog">Blog</a></li>
                  <li><a class="mobile-nav-link" href="https://grameenfoundation.org/stories/in-the-news">In the News</a></li>
              </ul>
           </li>
        </ul>
        <ul class="list-unstyled px-4">
           <li class="">
             <a class="text-uppercase text-primary font-weight-bold mb-3 mobile-nav-heading" href="/">Donate</a>
           </li>
         </ul>
      </div>
    </nav>

</template>

<script>
import Navigation from '@/components/navigation.vue'
import MobileNavigation from '@/components/mobilenavigation.vue'
import FooterNavigation from '@/components/footernavigation.vue'

export default {
  props: ['open']
}
</script>
