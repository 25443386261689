<template>
  <div class="home">
    <header class="">
      <picture>
      <source srcset="../assets/images/hero-sm.jpg"
        media="(max-width: 500px)" width="500" height="200">
        <source srcset="../assets/images/hero.jpg"
          media="(max-width: 501px)" width="1433" height="573">
      <img src="../assets/images/hero.jpg" alt="Women and crying child" class="w-100 d-block h-auto" width="1433" height="573" />
      </picture>
    </header>

    <section class="form-container mt-5">
      <div class="px-3 px-md-0">
        <h1 class="text-center h3 mt-4 mb-4">The world’s poorest are suffering more than ever before.</h1>



        <p><strong>Send help today.</strong></p>
        <p>A $5 donation will help us provide services to the most vulnerable, especially women, who are living in extreme poverty. Although women are major contributors to family farming and income; their own financial, food, nutritional and health needs — and those of their children — go unmet.</p>

        <p><strong>Feed families.</strong></p>
        <p>Give women equal status and the ability to have safe access to their money to support and educate their families. Right Now $10 provides financial tools to 20 women.</p>

        <p>Women have the power to end extreme poverty. With your help, we can teach them how.</p>


            </div>

      <DonateForm />

      <h3 class="text-center">We are proud to be reviewed and held accountable by these independent third-party organizations:</h3>

      <div class="charity-imgs text-center mb-5">
        <img src="../assets/images/guidestar-gold-logo.png" alt="GuideStar Gold" width="126" height="93" />
        <img src="../assets/images/other-logos-charity-navigator.svg" class="mx-3" alt="GuideStar Gold"  width="126" height="109" />
        <img src="../assets/images/charity-watch.png" alt="Charity Watch" width="126" height="98" />
      </div>
      <p class="text-center fst-italic"><small>Donations go to our programs with the most need.</small></p>
    </section>

    <footer class="d-flex justify-content-center read-our-stories py-4 px-3 px-lg-0">
      <h3 class="text-white me-4">Giving matters.</h3>
      <a href="https://grameenfoundation.org/stories/stories-of-change" class="btn btn-white">Read Our Stories <svg class="arrow" aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg></a>
    </footer>
  </div>
</template>

<script>
import DonateForm from '@/components/donateform.vue'

export default {
  name: 'Home',
  components: {
    DonateForm,
  },
}
</script>
