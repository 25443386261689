<template>
  <div class="home">
    <header class="hero-image hero-image--thankyou">
      <div class="container h-100 d-flex justify-content-center align-items-end pb-5">
        <h1 class="text-center text-white hero-image__h1 mb-0 mb-lg-4">Thank you for supporting Grameen Foundation!</h1>
      </div>
    </header>
    <section class="container px-3 px-md-0 mt-5">

      <p>Thank you for your support of Grameen Foundation and helping ensure that poor women around the world have the resources they need to improve life for themselves and their families.</p>

      <p>Your gift means so much. You are helping to unlock the potential of the world's poorest, most amazing, and strongest women to lift themselves out of extreme poverty.</p>

      <p>Your donation supports the recruitment and training of new Grameen Community Agents like Komal, pictured here. As a Mobile Money agent, Komal goes door-to-door helping her neighbors pay bills, repay loans, withdraw money, and access pensions digitally, so they don't have to take off work and lose a half-a-day's pay to walk to the nearest bank, six miles away.</p>

      <p>On average, every Grameen Community Agent helps anywhere between 100 and 1,000 people and their families. Each of them naturally shares their new skills and information with their neighbors, helping the entire community lift itself up.</p>

      <p class="mb-5">This work simply wouldn't be possible without you. Thank you for your generosity, compassion, and shared belief that a better future for all is possible.</p>
<br/><br/>

      <h3 class="text-center">We are proud to be reviewed and held accountable by these independent third-party organizations:</h3>

      <div class="charity-imgs text-center mb-5">
        <img src="../assets/images/guidestar-gold-logo.png" alt="GuideStar Gold" />
        <img src="../assets/images/other-logos-charity-navigator.svg" class="mx-3" alt="GuideStar Gold" />
        <img src="../assets/images/charity-watch.png" alt="Charity Watch" />
      </div>

    </section>

    <footer class="d-flex justify-content-center read-our-stories py-4 px-3 px-lg-0">
      <h3 class="text-white me-4">Giving matters.</h3>
      <a href="https://grameenfoundation.org/stories/stories-of-change" class="btn btn-white">Read Our Stories <svg class="arrow" aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path></svg></a>
    </footer>
  </div>
</template>

<script>
import DonateForm from '@/components/donateform.vue'

export default {
  name: 'Home',
  components: {
    DonateForm,
  },
}
</script>
