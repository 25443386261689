import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCurrencyInput from 'vue-currency-input'
import VueAnalytics from 'vue-analytics'

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'USD' }
}

Vue.use(VueCurrencyInput, pluginOptions)

Vue.config.productionTip = false

import "./assets/style.scss";

Vue.use(VueAnalytics, {
  id: 'UA-185068141-1',
  ecommerce: {
    enabled: true
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
