<template>
  <div class="layout" id="app">
    <header class="header p-3 px-xl-0">
      <div class="d-flex align-items-center justify-content-between px-xl-0 container">
        <strong class="me-lg-5">
          <a href="https://grameenfoundation.org/"><img src="./assets/images/grameen-logo.svg" alt="Grameen Foundation" width="242" height="57" /></a>
        </strong>
        <Navigation />

        <div class="mobile-toggle" data-toggle="mobiledropdown" aria-haspopup="true" aria-expanded="false" @click="open = !open">
          <div class="bar top" :class="{ 'close-hamburger': open }"></div>
          <div class="bar middle" :class="{ 'close-hamburger': open }"></div>
          <div class="bar bottom" :class="{ 'close-hamburger': open }"></div>
        </div>
      </div>
    </header>
    <MobileNavigation :open="open" />
    <router-view/>
    <footer class="container mt-5">
      <a href="https://grameenfoundation.org/"><img src="./assets/images/grameen-logo.svg" class="mb-4" width="242" height="57" alt="Grameen Foundation" /></a>
      <FooterNavigation />
      <p class="copyright mt-5 mb-4">©2021 Grameen Foundation. All rights reserved. Grameen Foundation is a registered trademark. | Grameen Foundation is a 501(c) (3) non-profit organization. | Fed Tax ID# 73-1502797</p>
    </footer>
    <div class="color-strip"></div>
  </div>
</template>

<script>
import Navigation from '@/components/navigation.vue'
import MobileNavigation from '@/components/mobilenavigation.vue'
import FooterNavigation from '@/components/footernavigation.vue'

export default {
  data() {
   return {
      open: false
    }
  },
  components: {
    FooterNavigation,
    MobileNavigation,
    Navigation
  },
  mounted() {
    const links = document.querySelectorAll("a");

    const urlParams = new URLSearchParams(window.location.search);
    let utmSource = 'give';
    let utmMedium = 'web';
    let utmCampaign = 'elev2021';

    if(urlParams.get('utm_campaign')) {
      utmCampaign = urlParams.get('utm_campaign');
    }

    links.forEach(function(link) {
      if(link.href.includes("https://grameenfoundation.org/")) {
        link.href = link.href + `?utm_source=give&utm_medium=web&utm_campaign=${utmCampaign}`;
      }
    });
  },
}

</script>
